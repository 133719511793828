import {getCookie} from "../../../../common/getCookieLang";

export const state = {
    fetching: false,
    fetched: false,
    order: {
        id: '',
        currency: '',
        address: '',
        tag: null,
        network: '',
        createdAt: '',
        expiresAt: '',
        amount: '0',
        amountUSD: '0',
        received: '0',
        status: 'init',
        order: '',
        description: '',
        returnUrl: '',
        buyUrl: '',
        contract: '',
        currencyAlias: '',
        transactions: [],
        organizationName: ''
    },
    themeStatus: '',
    lang: localStorage.getItem('lang')  || 'en',
    toggle: true,
    openedModal: null,
    openedModalPayload: {}
};
