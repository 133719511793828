<template>
  <div class="checkout-main-page" >
    <checkout-header />
    <dashboard />
    <history-modal />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import {i18n} from "@/public/i18n";

export default {
  name: "CheckoutMainPage",
  components: {
    CheckoutHeader: () => import(/* webpackChunkName: "entries/checkout/components/Header" */ '@/entries/checkout/components/Header.vue'),
    Dashboard: () => import(/* webpackChunkName: "entries/checkout/components/Dashboard" */ '@/entries/checkout/components/Dashboard.vue'),
    HistoryModal: () => import(/* webpackChunkName: "/entries/checkout/components/Modal/HistoryModal" */ '@/entries/checkout/components/Modal/HistoryModal'),
  },
  computed: {
    ...mapState(['toggle', 'themeStatus']),

    background() {
      if (!this.toggle) return {'--background': '#E7F0FF'};
      return {'--background': '#ffffff'};
    },
  },
  // created() {
  // },
  mounted() {
    setInterval(() => {
      this.fetchOrder()
    }, 20 * 1000);

    this.updateWidth();
      this.toggleTheme();
    window.addEventListener('resize', this.updateWidth);
      const queryString = window.location.search
      const urlParams = new URLSearchParams(queryString);
      const urlTheme = urlParams.get('theme')
      if (urlTheme === null) {
          // const localStorageTheme = localStorage.getItem('data-theme');
          urlParams.set('theme', this.themeStatus ? 'dark' : 'light')
          window.history.replaceState( {}, import.meta.env.VITE_PRODUCT_NAME, `${window.location.origin}${window.location.pathname}?${urlParams.toString()}` );
      }
  },
  methods: {
    ...mapActions([
      'fetchOrder',
    ]),
    ...mapMutations('app', ['setTheme']),
    updateWidth() {
      this.width = window.innerWidth;
    },
    toggleTheme() {
        const queryString = window.location.search
        const urlParams = new URLSearchParams(queryString);
        const urlTheme = urlParams.get('theme')
        const urlBoolean = urlTheme === 'dark'
        console.log(urlTheme)
        if (urlTheme !== null) {
            console.log('che theme created')
            this.setTheme(urlTheme === 'dark' ? 'light' : 'dark')
            this.$store.commit('changeThemeStatus', urlBoolean);

            if (urlBoolean) document.documentElement.setAttribute("data-theme", "public-dark");
            else document.documentElement.setAttribute("data-theme", "public-light");
            return
        }

      const themeOsDark = window.matchMedia("(prefers-color-scheme: dark)");
      const theme = localStorage.getItem('data-theme');

      this.setTheme(theme === 'dark' ? 'light' : 'dark') //пишем тему в стор

      if (theme === 'public-dark') {
        this.$store.commit('changeThemeStatus', true);
        document.documentElement.setAttribute("data-theme", "public-dark");

      } else if (theme === 'public-light') {
        this.$store.commit('changeThemeStatus', false);
        document.documentElement.setAttribute("data-theme", "public-light");
      } else if (themeOsDark.matches) {
        this.$store.commit('changeThemeStatus', true);
        document.documentElement.setAttribute("data-theme", "public-dark");
      } else {
        this.$store.commit('changeThemeStatus', false);
        document.documentElement.setAttribute("data-theme", "public-light");
      }
    },
  },
}
</script>

<style lang="scss">
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600;700&display=swap");
  .main-body {
    background: var(--body-color);
  }
  .checkout-main-page {
    position: relative;
    height: 100vh;

    .dashboard, .not-found-page {
      position: absolute;

      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      max-height: fit-content;

      @include below_tablet {
        top: 105px;
        transform: translate(-50%, 0);
      }

      max-width: 623px!important;
    }
  }
</style>
